<template>
  <div class="contact-main">
    <div class="blob"></div>
    <div class="container">
      <div class="row g-custom-lg">
        <div class="col-md-6">
          <div class="editor text-lg mb-custom-lg">
            <h1>با ما در تماس باشید</h1>
          </div>
          <img class="mt-5 astro-contact" :src="require(`@/assets/images/astro/astro-contact2.webp`)" alt=""
               width="300">
        </div>
        <div class="col-md-6">
          <div class="row g-custom-xs justify-content-between flex-column">
            <div class="col-12">
              <div class="row">
                <div class="col-auto">
                  <h4>
                    <img class="inline" :src="require(`@/assets/images/contact/icon-contact1.svg`)"
                         alt="baseone icon-contact1">
                  </h4>
                </div>
                <div class="col">
                  <h4 class="mb-2">آدرس</h4>
                  <p>تهران، خیابان نلسون ماندلا، خیابان تابان غربی، پلاک 8، واحد 2</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-auto">
                  <h4>
                    <img class="inline" :src="require(`@/assets/images/contact/icon-contact2.svg`)"
                         alt="baseone icon-contact2">
                  </h4>
                </div>
                <div class="col">
                  <h4 class="mb-2">تلفن</h4>
                  <p><a href="tel:+982188781506" class="d-block max-content" style="direction: ltr;">021 8878 15 06</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-auto">
                  <h4>
                    <img class="inline" :src="require(`@/assets/images/contact/icon-contact3.svg`)"
                         alt="baseone icon-contact3">
                  </h4>
                </div>
                <div class="col">
                  <h4 class="mb-2">ایمیل</h4>
                  <p><a href="mailto:hello@baseone.uk">tarsimdadeh@gmail.com</a></p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-auto">
                  <h4>
                    <img class="inline" :src="require(`@/assets/images/contact/icon-contact4.svg`)"
                         alt="baseone icon-contact4">
                  </h4>
                </div>
                <div class="col">
                  <h4 class="mb-2">ما را دنبال کنید</h4>
                  <div class="row g-2">
                    <div class="col-auto">
                      <a class="social-button" href="https://www.linkedin.com/company/tarsim-dade-afzar"
                         target="_blank">
                        <img :src="require(`@/assets/images/contact/icon-social3.svg`)"
                             alt="">
                      </a>
                    </div>
                    <div class="col-auto">
                      <a class="social-button" href="https://instagram.com/tarsimdade?utm_medium=copy_link"
                         target="_blank">
                        <img :src="require(`@/assets/images/contact/icon-social4.svg`)"
                             alt="">
                      </a>
                    </div>
                    <div class="col-auto">
                      <a class="social-button" href="https://www.aparat.com/tarsimdade" target="_blank">
                        <img :src="require(`@/assets/images/contact/aparat.png`)" width="20"
                             alt="">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-spacer"></div>
    <section>
      <div class="container mb-5">
        <div>
          <form method="post">
            <div class="row g-custom-lg">
              <div class="col-md-6 d-flex flex-column justify-content-between">
                <div class="editor mb-custom-md">
                  <h3>یک گفتگو را شروع کنید</h3>
                  <p>نظرات، انتقادات و پیشنهادات خود را با ما در میان بگذارید</p>
                </div>
                <div class="mb-custom-sm mt-3">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label">نام و نام خانوادگی</label>
                      <input class="form-control bg-transparent text-white" type="text">
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">تلفن همراه</label>
                      <input class="form-control bg-transparent text-white" type="tel">
                    </div>
                    <div class="col-12 mt-3">
                      <label class="form-label">توضیحات خود را وارد نمایید</label>
                      <textarea class="form-control bg-transparent text-white"></textarea>
                    </div>
                    <div class="col-12 mt-3">
                      <button class="btn btn-outline-light d-flex align-items-center m-0">
                        ارسال پیام
                        <i class="fas fa-arrow-left ms-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <img class="m-0 mt-5 astro-contact" :src="require(`@/assets/images/astro/astro-contact-form2.webp`)"
                     width="350" alt="">
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    let cursor = document.querySelector('.blob');

    document.addEventListener('mousemove', function (e) {
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
    });


  }
}
</script>

<style lang="scss" scoped>
.contact-main {
  padding-top: 8rem;
  --container-padding: 8.4vw;
  --vw: calc(100vw - 2 * var(--container-padding)) / 100;
  --font-size: calc(1.64 * var(--vw));

  h1 {
    font-size: max(55px, 2.5 * var(--font-size));
    --line-height: 1em;
    font-weight: 700;
    margin: 0;
  }
}

.section-spacer {
  height: max(100px, 8vw);
}

form {
  h3 {
    font-size: max(36px, 1.54 * var(--font-size));
    --line-height: 1.05em;
    font-weight: 700;
  }
}

.astro-contact {
  animation: float linear 2.5s infinite alternate;

  @keyframes float {
    0% {
      transform: translateZ(0);
    }
    to {
      transform: translate3d(0, 10px, 0);
    }
  }
}

.g-custom-xs, .gx-custom-xs {
  --custom-xs: max(15px, 1rem);
  --bs-gutter-x: var(--custom-xs);
  --bs-gutter-y: var(--custom-xs);
}

.flex-column.row {
  min-height: calc(100% + var(--bs-gutter-y));
}

img.inline {
  height: 1.5em;
  margin: 0;
}

.col-auto, .col {
  h4 {
    font-size: max(22px, 1.25 * var(--font-size));
    --line-height: 1.29em;
  }
}

.social-button {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    filter: brightness(0);
    transition: .25s;
  }
}

.blob {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  background-image: linear-gradient(#3722ff 10%, #3403af);
  filter: blur(250px);
  transition: all 450ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 15px), -50%);
  z-index: 9;
}


</style>